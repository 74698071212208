export default {
  name: "plugins",
  components: {},
  props: [],
  data() {
    return {
      items: [
        {
          image: "opencart.png"
        },
        {
          image: "woocommerce.png"
        },
        {
          image: "laravel.svg"
        },
        {
          image: "prestashop.png"
        },
        {
          image: "microsoft_net.png"
        },
        {
          image: "magento.png"
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
